<template>
	<div class="page">
		<div class="title">
			<p class="b">{{lang==6?'宝爱捷（中国）个人信息保护政策':'PAIG (China) Personal Data Protection Policy'}}</p>
			<p class="yellowLine"></p>
		</div>
		<div class="content">
			<div class="text textEN" v-if="lang!=6">
				<p class="b">PAIG (China) Personal Data Protection Policy</p>
				<p>PAIG (China) appreciates a lot your visit to our website and your interest in products and services
					offered herein. Personal data acquired during your visit to official website of PAIG (China) will be
					used to assess your interest in PAIG (China) and its cars and services. These data will only be used
					by
					PAIG (China) and its affiliated companies, and will not be disclosed or resold to other companies or
					organizations.</p>
				<p>PAIG (China) Group (hereinafter referred to “PAIG (China)” or “Us”) provides you with cars, spare
					parts
					and other accessories (hereinafter referred to as “Products”) and numerous online and offline
					services
					related to the Products, including but not limited to our websites, telematics and other in-vehicle
					technologies, commodity exhibition exchange and other services (collectively referred to as
					“Services”).
					You may be requested to provide your personal data when you contact with us and/or our affiliated
					companies in terms of our products or services. We pay lots of attention to your personal data
					protection and personal privacy, and understand that you may care about how your personal data will
					be
					used and shared. Therefore, this Personal Data Protection Policy is developed to elaborate how we
					collect, use, disclose, share and store your personal data.</p>
				<p>Please carefully read this Policy. By using our product and/or service, you agree that: you are 18
					years
					old or above and you fully understand and accept all clauses and contents specified herein. If you
					do
					not agree with this Policy, you may choose not to use our product and/or service.</p>
				<p class="b">Scope of Personal Data</p>
				<p>“Personal Data” in this Personal Data Protection Policy refer to the information we collect from
					individuals to identify personal identity, which are divided into personal data that can be used
					independently (such as name and ID information) and personal data that must be used in combination
					with
					other information (such as location and vehicle status). Personal data collected by us may include
					these
					two kinds of data at the same time. For personal data that can be used independently, we will
					collect
					these data with prior notice and prior consent from you; for personal data that cannot be used
					independently, we may collect, use, transfer, disclose or dispose of these data for any purpose in
					the
					case that they’re not used together with other information.</p>
				<p>Personal Data collected from you include but not limited to your name, mailing address, phone number,
					email address, other contact information, age, birthdate, gender, educational background,
					occupation,
					language, post code, area code, personal photo, interest preference, hobby, ID card information,
					driver
					license information, credit card information, unique vehicle identifier, geographical location,
					vehicle
					status, behavior information, etc.</p>
				<p class="b">Means of Collecting Personal Data</p>
				<p>We will collect and store your personal data through various means:</p>
				<ul>
					<li>The information you provide to us on a voluntary basis when you visit our website or you
						participate
						in our website service or other online services (for example, you contact with us through our
						website to raise questions, make comments or engage in online surveys);</li>
					<li>When you register to obtain our offline services (for example, you purchase our product or
						service
						or participate in our car club, etc.);</li>
					<li>When you use our telematics or other in-vehicle technologies;</li>
					<li>When you download or use the applications (such as in-vehicle systems) developed by us or the
						company designed by us;</li>
					<li>When you follow our social media (such as Weibo and WeChat);</li>
					<li>When you release personal data through platforms available to the public (such as social media
						websites like BBS, Weibo and WeChat);</li>
					<li>When you provide information to the representatives of our Call Center;</li>
					<li>The information we collect from our dealers, for example, when you engage in test drives,
						purchase
						relevant product or service, or repair your car at our dealers;</li>
					<li>The information we collect from your transactions with financial institutions, such as applying
						for
						auto loans;</li>
					<li>The information we collect from our business partners and/or service providers (affiliated and
						non-affiliated companies), for example, we may obtain your personal data from auto show
						organizers
						or other third parties to access to potential customers.</li>
					<li>We may also collect your information from other means and add it to our customer database.</li>
				</ul>
				<p class="b">Collecting Data through Cookies</p>
				<p>We will learn about the customers’ usage of our website through Cookies and other tracking
					technologies.
					Cookies are a kind of unique identifiers for identifying your device. By doing this, we (including
					those
					affiliate sites for which we provide ads) can recommend personalized products and ads to you. By
					classifying and summarizing of the data collected through Cookies, we provide more targeted and more
					valuable products and services to different groups of customers. Personal data collected through
					Cookies
					cannot be used independently, and you understand and agree that we will use the collected data
					together
					with other information. Most of web browsers will automatically enable cookies, but you may
					reconfigure
					your browser to disenable Cookies at any time. However, Cookies will allow you to enjoy more premium
					and
					personalized services. If you choose to disenable Cookies, it may be impossible for you to fully
					experience or enjoy all services provided on our website. Thus, it is suggested that you’d better
					enable
					Cookies.</p>
				<p class="b">Collecting Data through Surveillance Cameras</p>
				<p>When you enter and stay at any outlet of our dealers or any of our places, we may collect your
					personal
					photos through surveillance cameras there. You agree and allow us to collect your personal photos
					through these surveillance cameras. We only collect, use and process the photos for ant-theft
					purpose,
					security monitoring purpose or other purposes specified herein (see details below).</p>
				<p class="b">Collecting Data through Call Center</p>
				<p>In order to improve service quality and follow up your request, we may collect your personal data or
					make
					recordings via electronic media means during your call to us through hotline. You understand and
					agree
					that we collect your personal data and make recordings during the call.</p>
				<p class="b">Collecting Data through Mobile Devices or In-vehicle Systems</p>
				<p>When you download or use the applications (such as in-vehicle systems) developed by us or our
					designated
					company, we may receive the data about your geographical locations, vehicle statuses and your mobile
					devices (including the unique identifier for your mobile device). These data will be used to
					determine
					approximate location of yourself, your device or your vehicle. In this way, we’re able to provide
					and
					enhance our positioning service or other personalized services. You hereby understand and agree that
					we
					collect the above personal data about geographical locations, vehicle statuses, unique mobile device
					identifiers, etc.</p>
				<p class="b">Collecting Data through Free WI-FI System</p>
				<p>When you use the free WI-FI at the outlets of our dealers, your personal data may be collected by
					this
					free WI-FI system, including your mobile phone number, relevant device ID, geographical location,
					Internet and mobile Internet browsing records. During our business activities, we may use these data
					independently or together with other information collected about you, thus providing better
					personalized
					services for you. You hereby understand and agree that we collect the above personal data about
					mobile
					phone number, relevant device ID, geographical location, browsing records, etc.</p>
				<p class="b">Consequences of Refusing to Provide Personal Data</p>
				<p>You may choose not to provide your personal data. If it is the case, we may not be able to provide
					you
					with our products or services, or to respond to your questions about product or service failures. In
					this case, we will not be liable for any losses you may suffer.</p>
				<p class="b">How We Use Your Personal Data</p>
				<p>We will use the collected data for the following purposes (hereinafter refer to as “Purposes of
					Collecting Personal Data”):</p>
				<ul>
					<li>Manage our products and services, provide you with our products and services, and communicate
						with
						you in time for your better use of our products and services;</li>
					<li>Customize personalized products and services for you (including regional and personalized
						customizations), so that you may have better experience;</li>
					<li>Support our performance and operation of services, respond to your consultation and request, and
						deal with your transaction;</li>
					<li>Provide you with aftersales services and support, and receive your feedbacks;</li>
					<li>Inform you of our latest products, services, derivative products and services, upcoming events,
						lucky draw, competition or promotion activities in a timely manner;</li>
					<li>Use your personal data for data analysis, research and other internal purposes to enhance the
						quality of our products and services and the communication with our customers, and to solve
						their
						problems in time;</li>
					<li>Use your personal data for sending importance notice on product or service safety, such as
						recalling, market action and other information. Since they are of vital importance to your
						personal
						safety, we strongly suggest you not to refuse the receipt of these information;</li>
					<li>Investigate frauds that have occurred or possibly occur, or prevent frauds or other potential
						illegal activities;</li>
					<li>Other purposes agreed by you.</li>
				</ul>
				<p>In some cases, in order to provide and perfect our products, services, contents and ads, we may also
					combine your personal data and non-personal data for analysis and research.</p>
				<p>We will not use your personal data against or beyond the above purposes.</p>
				<p class="b">Disclose or Share Your Personal Data to/with Third Parties</p>
				<p>We respect your personal privacy and obey relevant laws and regulations. We promise to keep your
					personal
					data collected in strict confidence, and will not sell them to others. We only share your personal
					data
					with third parties for the above purposes, or by obeying this Policy or at least by providing the
					same
					protection with this Policy. Unless otherwise specified, these third parties do not collect, use,
					transfer, or dispose of your personal data on behalf of PAIG (China). Hence, PAIG (China) will not
					be
					liable for the collection, use, transfer or disposal of your personal data by third parties.</p>
				<ul>
					<li>Our associates and affiliated companies: In order to make sure that we offer services of similar
						quality to you, we may share your personal data with our associates and affiliated companies;
					</li>
					<li>Financial institutions, auto insurance companies, used car companies, and extended warranty
						companies: We will share them your personal data related to vehicle financing, used car
						assessment
						and sales, vehicle warranty extension, etc.;</li>
					<li>Business partners: We have cooperated with numerous third parties. In order to promote
						cooperation
						and launch or update premium products and services, we may share your personal data with
						third-party
						partners.</li>
					<li>Third-party service providers: We hire third-party companies (such as suppliers, service
						providers,
						etc.) to provide relevant services to our customers, including but not limited to auto loans,
						auto
						insurance, vehicle information, roadside assistance, call visits, sending of letters, emails or
						SMS,
						customer information elimination, data analyses, marketing assistance, customer satisfaction
						survey,
						response to customers’ enquiries, dealing with customers’ requests, etc. These third parties are
						only allowed to access, use, dispose of or keep your personal data for performing their
						obligations
						or facilitating us to collect your personal data. They are not allowed to use your personal data
						for
						any other purposes.</li>
					<li>Call Center: We set up a Call Center to uniformly receive your requests and feedbacks, and to
						enhance our service quality. In order to provide effective services for you, we may share your
						personal data with Call Center;</li>
					<li>Business Transfer: For sustainable development of our businesses, we may restructure, merge or
						sell
						some of our businesses. When our assets are transferred to third parties dur to the
						restructuring,
						merger or selling, all relevant personal data collected by us will also be transferred to these
						third parties;</li>
					<li>Investigations or Audits: In accordance with applicable laws and regulations, we may be subject
						to
						investigations or audits from relevant governmental or regulatory authorities from time to time.
						During these investigation or audit procedures, if we’re required to disclose your personal data
						but
						cannot inform you of such disclosure immediately, you hereby understand and agree that we can
						disclose your personal data to relevant third parties during these procedures without obtaining
						your
						additional consent.</li>
					<li>Others: As requested by other laws and regulations, legal proceedings, court orders, lawsuits
						and/or
						public organs and governmental departments, or in consideration of national security, law
						enforcement and other events of public importance, or for the goal of implementing our clauses
						and
						conditions or protecting our business or users, if necessary or appropriate, we may also
						disclose
						your personal data to relevant third parties;</li>
					<li>Otherwise agreed by you: When you otherwise agree or give us instructions, we may disclose your
						personal data to relevant third parties.</li>
				</ul>
				<p class="b">Processing and Storage of Personal Data</p>
				<p>We provide some conveniences for you to ensure your personal data are accurate, complete and updated
					in
					time. We will keep your personal data within the required period for achieving the purposes
					specified
					herein, unless any applicable law requires or permits to keep these data within a longer period.</p>
				<p>It is of great important to maintain accurate and complete personal data. You’re much appreciated to
					help
					us update your personal data in time so that they are accurate and complete by sending emails to
					info@paig.com.cn.</p>
				<p class="b">Options</p>
				<p>We may send marketing information to you via email, telephone, mobile phone or text message
					(including
					SMS and MMS), letter or other means. Our goals include but not limited to: informing you of latest
					information about our products or services, inviting you to participate in our marketing activities,
					or
					releasing announcements about our products or services.</p>
				<p>If you choose not to receive marketing information from PAIG (China) and its dealers, you may
					unsubscribe
					it by following our instructions. Please note that it may take a few days to deal with your
					unsubscribing request, and you may still receive promotional or marketing information during these
					days.
				</p>
				<p class="b">About Minors’ Personal Data</p>
				<p>We will not knowingly collect personal data of minors under 18 years old. If it is found that we have
					collected personal data of minors under 18 years old without the consent of their guardians, we will
					take relevant measures as soon as possible to delete such data.</p>
				<p class="b">Third Parties’ Collection of Your Personal Data Via Our Product or Service</p>
				<p>Our products and services may contain the links of third parties’ websites, products and services.
					Our
					products or services may also use or provide products or services from third parties, such as
					telematics. For personal data collected by third parties through our systems, products and services
					(which may contain location data, contact information, etc.), the use, processing, storage, transfer
					and
					destruction of these data must obey the privacy policies of third parties. If we do not grab or
					collect
					your personal data during this process, we will not take relevant responsibility, and we wish that
					you
					have understood privacy policies of these third parties. But, we will keep your personal data in
					strict
					confidence in accordance with the clauses herein if we grab or collect them during this process.</p>
				<p class="b">Updates</p>
				<p>We have the right to revise and update the Policy at any time. If we make significant changes in this
					Policy, we will immediately release the latest privacy policy on our website http://www.paig.com.cn.
					Unless otherwise stated, the latest valid personal data protection policy apply to you and all of
					your
					personal data.</p>
				<p>Please read this Policy thoroughly and carefully. After that, if you continue to provide your
					personal
					data or purchase our product or use our service, it means that you have fully understood and
					accepted
					all clauses and contents contained herein.</p>
			</div>
			<div class="text" v-if="lang==6">
				<p class="b">宝爱捷（中国）个人信息保护政策</p>
				<p>宝爱捷（中国）感谢您对本网站的访问和您对网站所提供产品和服务的兴趣。在访问宝爱捷（中国）官方网站过程中所记录的您的个人资料将被用来评估您对公司、车辆和服务的兴趣。这些信息将只被宝爱捷（中国）及其所属公司使用。不会泄露或转卖给其他公司或组织。
				</p>
				<p>宝爱捷（中国）集团（以下简称“宝爱捷（中国）”
					或者“我们”）向您提供车辆、配件及其他附属产品（统称为“产品”）及与产品相关的众多网上和线下服务，包括但不限于我们的网站、车载信息系统和其他车载技术、商品展览交流及其他服务（统称为“服务”）。当您与我们和/或我们的关联公司就产品和服务取得联系时，都可能会被要求提供您的个人信息。我们非常重视您的个人信息保护与个人隐私，也理解您可能关心您的个人信息是如何被使用和共享的，因此我们制订了本个人信息保护政策，阐述了我们如何收集、使用、披露、共享以及储存您的个人信息。
				</p>
				<p>请您仔细阅读本个人信息保护政策。通过使用我们的产品和/或服务，您同意：您已满十八周岁且已完全理解并接受本个人信息保护政策的全部条款和内容。如果您不同意本个人信息保护政策，您可以不使用我们的产品和/或服务。
				</p>
				<p class="b">个人信息范围</p>
				<p>本个人信息保护政策所称“个人信息”，是指我们从个人收集的
					可用于识别特定个人身份的信息，其中又可分为能够单独使用的个人信息（如姓名、身份证信息）和不能单独使用而必须与其他信息结合使用的个人信息（如位置信息、车辆状况）。我们所收集的个人信息可能同时包括这两类信息。对于能够单独使用的个人信息，我们只会在事先告知并取得您同意的情况下收集；对于不能单独使用的个人信息，在非结合使用的情况下，我们可出于任何目的收集、使用、转让和披露该等信息。
				</p>
				<p>我们可能向您收集的“个人信息”包括但不限于您的姓名、邮寄地址、电话号码、电子邮件地址、其他联系方式、年龄、出生日期、性别、学历、职业、语言、邮编、区号、个人图像、兴趣偏好、身份证信息、驾照信息、信用卡信息、唯一设备标识符、地理位置信息、车辆状态、行为信息等。
				</p>
				<p class="b">个人信息的收集途径</p>
				<p>我们会通过多种方式收集并存储您的个人信息：</p>
				<ul>
					<li>当您访问或者参与我们的官网或者其他网上服务时（例如您通过我们的网站联系我们提出问题、进行评论或者参与在线调查时），您自愿向我们提供的信息；</li>
					<li>当您为了获得我们的线下服务而进行注册时（例如您购买产品、服务或者参加我们的品牌车友俱乐部等）；</li>
					<li>当您使用我们的车载信息系统或其他车载技术时；</li>
					<li>当您下载或使用我们或我们指定的公司开发的应用（如车载系统）；</li>
					<li>当您关注我们的社交媒体（如微博、微信）时；</li>
					<li>当您通过公众可接触的方式（例如您在社交媒体网站如BBS、微博和微信上发布信息）发布个人信息时；</li>
					<li>当您向我们呼叫中心的代表提供信息时；</li>
					<li>我们从集团下的授权经销商处收集，例如当您通过我们的授权经销商进行试乘试驾车、购买相关产品和服务、进行车辆维修时进行收集；</li>
					<li>我们从金融性公司就您与他们的交易进行收集，比如申请购车贷款；</li>
					<li>从我们的商业合作伙伴和/或服务提供商（关联公司和非关联公司）处收集，例如我们可能处于获取潜在客户之目的，从车展主办方和其他第三方处获取有关您的个人信息；</li>
					<li>我们还可能从其他途径收集到关于您的信息并加入我们的客户信息库。</li>
				</ul>
				<p class="b">通过Cookies收集信息</p>
				<p>我们会通过Cookies和其他追踪技术了解客户使用我们网站的情况。Cookies是一种能够识别您设备的特定识别符号，通过识别您的设备，我们可以为您推荐个性化商品和广告（包括由本网站提供广告内容的联盟网站）。我们将通过Cookies收集的信息分类汇总，用于向不同群体客户提供更有针对性、更有价值的产品信息和服
					务。通过Cookies收集的信息属于不能单独使用的个人信息，您理解并同意我们将通过Cookies收集的信息与您的其他信息结合使用。大部分网页浏览器会自动打开
					Cookies，但您可以随时调整您的浏览器设置，选择关闭Cookies。然而，Cookies
					能让您享受我们更优质、更个性化的服务，如果您关闭了Cookies，可能无法完全体验或者参与我们网站上提供的所有服务，所以我们建议您将其设置为打开状态。</p>
				<p class="b">通过监控摄像头收集信息</p>
				<p>当您进入并停留于我们集团旗下的授权经销商的门店或任何我们的场所，我们可能会通过店内或场所内的视频监控摄像头收集您的个人图像。您同意并准许我们通过位于店内或场所内的视频监控摄像头收集您的个人图像。我们只会依照防盗用途、安全监控用途或本个人信息保护政策内所列的个人信息收集目的（在下文中定义）而收集、使用及处理该图像。
				</p>
				<p class="b">通过呼叫中心收集信息</p>
				<p>当您拨打我们的服务热线，为了提高服务质量以及跟进您的请求，我们可能会在通话期间向您收集个人信息，或者通过电子媒介手段进行电话录音。您理解并同意我们在语音通话的过程中收集您的个人信息并进行电话录音。
				</p>
				<p>通过移动设备或车载系统收集信息</p>
				<p>当您下载或使用我们或我们指定的公司开发的应用（如车载系统）时，我们将可能收到关于您地理位置、车辆状况以及您的移动设备的信息（如包含识别您的移动设备的特定号码）。这些数据将被用于确定您、您设备或者您车辆的大致位置，以便我们能提供并完善我们的定位服务或其他个性化服务。您理解并同意我们收集上述地理位置、车辆状况、移动设备特定标识码等的个人信息。
				</p>
				<p class="b">通过免费WIFI系统收集信息</p>
				<p>当您在我们集团旗下的授权经销商场所内使用免费WIFI时，该免费WIFI系统将可能会收集您的手机号码、相关设备编号、地理位置信息、互联网及移动互联网应用浏览记录等个人信息，我们可能在业务活动中单独使用该等信息或者将该等信息与我们收集的您的其他个人信息相结合，从而使我们更好地为您提供个性化的服务。您理解并同意我们收集上述手机编号、相关设备编号、地理位置信息、浏览记录等个人信息。
				</p>
				<p class="b">拒绝提供个人信息的后果</p>
				<p>您可以不提供您的个人信息，如果您选择不提供您的个人信息，我们将可能无法为您提供我们的产品或服务，也可能无法回应您在使用我们的产品或服务时所遇到的问题。对于您可能因此遭受的损失，我们将不承担责任。</p>
				<p class="b">我们如何使用您的个人信息</p>
				<p>我们会将收集到的个人信息用于以下目的（以下简称“个人信息收集目的”）：</p>
				<ul>
					<li>管理我们的产品和服务，向您提供我们的产品及服务，并就您使用我们产品与服务的情况进行及时沟通；</li>
					<li>将我们向您提供的产品及服务进行个性化定制（包括地区化定制以及个人化定制），使得您在使用我们的产品与服务时有更好的个性化体验；</li>
					<li>支持我们服务的履行与运作，回应您的咨询及请求，处理您的交易；</li>
					<li>向您提供售后服务及支持，接收您的反馈；</li>
					<li>将我们的最新产品、服务、衍生产品及服务、活动预告、抽奖、竞赛或促销推广活动等及时告知您；</li>
					<li>将您的个人信息用于数据分析和研究等内部目的，以改进我们的产品、服务和与客户之间的沟通，及时解决客户的问题；</li>
					<li>使用个人信息向您发送产品/服务安全的重要通知，例如召回及市场行动等信息。由于这些信息对您的人身安全至关重要，我们强烈建议您不要拒绝接收此类信息；</li>
					<li>调查已经发生的或者可能的欺诈情形或者防止欺诈或者其他潜在的非法活动；</li>
					<li>以及您同意的其他方式。</li>
				</ul>
				<p>在某些情况下，为了提供并完善我们的产品、服务、内容和广告，我们也可能会将您的个人信息和非个人信息结合在一起进行分析研究。</p>
				<p>我们不会在违背上述个人信息收集目的的情况下，或超出本个人信息保护政策的范围外使用您的个人信息。</p>
				<p class="b">向第三方披露和共享您的个人信息</p>
				<p>我们尊重您的个人隐私并遵守相关的法律和法规，我们承诺严格保密所收集的您的个人信息，并且不会将您的个人信息出售给他人。我们仅会为达到个人信息收集目的，与遵守本个人信息保护政策或至少提供与本个人信息保护政策同样保护性惯例的以下第三方分享您的个人信息。除非另有声明，这些第三方并不代表宝爱捷（中国）收集、使用或转移个人信息等。因此，宝爱捷（中国）并不对这些第三方收集、使用或转移个人信息等行为负责。
				</p>
				<ul>
					<li>我们的关联公司及附属公司：为确保本公司集团成员向您提供大致相当的服务质量，我们可能会与我们的关联公司以及附属公司共享您的个人信息；</li>
					<li>金融性公司、车辆保险相关的公司、二手车公司、产品延长保修期运作公司：我们会同他们共享您的车辆融资相关的个人信息、二手车评估及销售相关的个人信息、产品延长保修期相关的个人信息；</li>
					<li>商业合作伙伴：我们与众多第三方有业务上的合作往来，为促进合作、推出优质及更新的产品及服务，我们可能会与第三方合作伙伴共享您的个人信息；</li>
					<li>第三方服务提供者：我们聘请第三方公司（如供应商、服务外包商等）为我们的客户提供相关服务，包括但不限于购车贷款、车辆保险、车载信息、路边救援、电话回访、发送信函、电子邮件或短信、清理客户信息、分析数据、提供市场营销帮助、客户满意度调查、接受客户咨询以及处理客户请求等等。此类第三方仅能为履行其职责或协助我们达到个人信息收集目的而访问、使用、处理或保留您的个人信息。除此以外，他们不能将您的个人信息用于任何其他目的；
					</li>
					<li>呼叫中心：我们设置了呼叫中心，以统一接收您的请求与反馈，提高我们的服务质量。为有效地为您提供服务，我们可能会向呼叫中心提供您的个人信息；</li>
					<li>业务转让：为持续发展我们的业务，我们可能会进行重组、合并或出售某些业务。当我们的资产因此被转移至相关第三方时，我们收集的一切个人信息也会随之转让给相关第三方；</li>
					<li>调查或审计：受适用的法律法规，我们可能会受到相关政府机构或监管部门不时的调查或审计。在相关调查或审计程序中，如果我们被要求披露您的个人信息而无法立即告知您该等披露，您同意我们在这些调查或审计程序中向相关第三方披露您的个人信息而无须获得您的额外同意；
					</li>
					<li>其他：应其他法律和法规、法律程序、法院命令、诉讼和/或公共机构和政府部门的要求，或者就国家安全、执法活动或具有公众重要性的其他事宜，或者为了执行我们的条款和条件或保护我们的经营或用户，披露您的个人信息是必须的或适当
						的，我们也可能会向相关第三方披露您的个人信息；</li>
					<li>当您另行同意时：当您另行同意或者给予我们指示时，我们可以向第三方披露您的个人信息；</li>
				</ul>
				<p class="b">个人信息的处理及储存</p>
				<p>我们提供了一些便捷的方法，让您能够确保您的个人信息准确、完整并及时更新。我们将在实现本个人信息保护政策中所述目的所必需的期间内保留您的个人信息，除非法律要求或允许在更长的期间内保留这些信息。</p>
				<p>您个人信息的准确性、完整性很重要，您可以联系info@paig.com.cn，帮助我们确保您的个人信息准确、完整并及时更新。</p>
				<p class="b">选择</p>
				<p>我们可能会通过电子邮件、电话、手机/文本消息（包括SMS 和MMS）或信函等方式向您发送营销类信息，包括但不限于：告知我们的产品或服务的最新信息、邀请您参加我们的市场活动、或者发出关于产品或服务的公告。
				</p>
				<p>如果您选择不要从宝爱捷（中国）及其集团下属授权经销商收取营销类信息，您可以根据我们提供的退订方式进行退订操作。敬请注意，可能需要几天时间处理您的此等退订要求，您可能在该期间仍然收到促销或营销信息。
				</p>
				<p class="b">未成年人</p>
				<p>我们不会在明知对方为年龄不满 18周岁的儿童的情况下收集其个人信息。如果我们发现我们在未经监护人同意的情况下收集了年龄不满 18 周岁的儿童的个人信息， 我们将采取措施尽快删除此等信息。</p>
				<p class="b">第三方通过我们的产品和服务向您收集个人信息</p>
				<p>我们的产品和服务中可能含有第三方网站、产品和服务的链接。我们的产品和服务也可能使用或提供来自第三方的产品或服务—
					例如，车载信息系统。第三方通过我们的系统、产品和服务收集的此类个人信息（其中可能含有位置数据或联系方式等），其使用、处理、保存、转移、保留及销毁等须遵守第三方关于隐私的政策规定。如果在这个过程中，我们并未因此而抓取或收集到您的个人信息，则我们不承担相关责任，且我们希望您能了解这些第三方的个人信息保护政策规定；但如果在这个过程中，我们因此抓取或收集到您的个人信息，则我们会严格遵守本个人信息保护政策的条款。
				</p>
				<p class="b">更新</p>
				<p>我们有权随时对本个人信息保护政策加以修订及更新。如果我们对个人信息保护政策做出重大变更， 我们将于我们的网站http://www.paig.com.cn
					即时发布最新的隐私政策。除另有声明外，最新有效的个人信息保护政策适用于我们关于您和您的所有个人信息。</p>
				<p>请您仔细阅读本个人信息保护政策，当您阅读完毕并继续提供您的个人信息，或购买我们的产品或使用我们的服务，表示您已完全理解并接受本个人信息保护政策的全部条款和内容。</p>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 70px;
		font-size: 18px;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px;
	}

	.title {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #FFF;
		padding: 0 111px 0;
		text-transform: uppercase;
	}

	.b {
		font-weight: bold;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	ul {
		margin-left: 15px;
	}

	.text {
		padding: 0px 0px 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;

		ul {
			margin-left: 25px;
		}
	}

	.textEN {
		.b {
			margin-top: 30px;
			line-height: 46px;
			font-weight: bold;
			font-size: 22px;
		}
	}
</style>